.rxlib-home-menu {
    margin-top: 4.6rem;
}

.rxlib-home-cards {
    display: flex;
}

.rxlib-home-cards div {
    margin-left: 0.5rem;
}