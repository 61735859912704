.bd-placeholder-img {
    user-select: none;
    font-size: 1.125rem;
    text-anchor: middle;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.login-content {
    width: 100%;
    margin: auto;
    padding: 15px;
    height: 100vh;
    background-color: #363636;
}

.login-spinner-button {
    margin-top: 0.75rem;
}

.form-label-group {
    position: relative;
    margin-bottom: 1rem;
}

.form-label-group input, .form-label-group label {
    padding: .75rem;
    height: 3.125rem;
}

.form-label-group label {
    top: 0;
    left: 0;
    width: 100%;
    cursor: text;
    display: block;
    margin-bottom: 0;
    line-height: 1.5;
    color: #495057;
    position: absolute;
    pointer-events: none;
    border-radius: .25rem;
    border: 1px solid transparent;
    transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input::-moz-placeholder {
    color: transparent;
}

.form-label-group input:-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-label-group input:not(:-moz-placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
}

.form-label-group input:not(:-ms-input-placeholder) {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
}

.form-label-group input:not(:-moz-placeholder-shown)~label {
    color: #777;
    font-size: 12px;
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.form-label-group input:not(:-ms-input-placeholder)~label {
    color: #777;
    font-size: 12px;
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.form-label-group input:not(:placeholder-shown)~label {
    color: #777;
    font-size: 12px;
    padding-top: .25rem;
    padding-bottom: .25rem;
}

/* Fallback for Edge
  -------------------------------------------------- */

@supports (-ms-ime-align: auto) {
    .form-label-group {
        display: flex;
        display: -ms-flexbox;
        flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
    }

    .form-label-group label {
        position: static;
    }

    .form-label-group input::-ms-input-placeholder {
        color: #777;
    }
}