.container-filtro-cotacao-preco .titulo-grupo {
    width: 100%;
    padding-left: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    color: var(--rxlib-fonte);
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    background-color: var(--rxlib-principal);
}

.container-filtro-cotacao-preco .conteudo-grupo {
    border-top: none;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    border: 1px solid #CCCCCC;
    border-radius: 0.0rem 0.0rem 0.5rem 0.5rem;
}

.conteudo-grupo-informacoes {
    padding-bottom: 9.4rem !important;
}

.table-medidas-mercadoria {
    margin-bottom: 0px;
}

.row-medidas-mercadoria>* {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.conteudo-grupo-medidas-mercadoria {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-bottom: 0.3rem !important;
}