.posicao-carga {
    width: 95%;
    margin: 0 auto;
    min-height: 100vh;
    max-width: 59.9375em;
    padding-top: 0.9375em;
    background-color: #FCFCFC;
    box-shadow: 0 0 0.5em #666;
    -moz-box-shadow: 0 0 0.5em #666;
    -webkit-box-shadow: 0 0 0.5em #666;
}

.posicao-carga-content {
    width: 100%;
    margin: auto;
    min-height: 100vh;
    background-color: #363636;
}

.posicao-carga-spinner {
    padding-top: 0.9375em;
    padding-top: 20% !important;
}

.linha-titulo {
    text-align: center;
    align-items: center;
}

.titulo {
    font-size: larger;
    font-weight: bold;
}

.titulo .valor {
    font-weight: normal;
}

.logo {
    max-width: 5em;
    max-height: 5em;
}

.linha-andamento {
    text-align: center;
    align-items: center;
}

.informacao .campo {
    font-weight: bold;
}

.informacao .valor {
    font-weight: normal;
}

.titulo-cliente {
    font-size: 1.125em;
    font-weight: bold;
}

.progresso-12 {
    width: 12%;
}

.progresso-33 {
    width: 33%;
}

.progresso-55 {
    width: 55%;
}

.progresso-76 {
    width: 76%;
}

.progresso-100 {
    width: 100%;
}

.progress-bar {
    background-color: #01B4B8;
}

.previsao {
    color: #01B4B8;
}

.informacao-rastreamento {
    text-align: center;
    font-size: 1.125em;
}

.informacao-rastreamento-table {
    margin-top: -0.75em;
}

.gerado-em {
    text-align: center;
    font-size: 0.75em;
    margin-bottom: 1.5em;
}

.recebido-por {
    font-size: 0.75em;
    margin-top: 0.65em;
}

.recebido-por .recebedor {
    font-weight: normal;
}

hr {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
}