/* rxlib - css v2.0.1 */

:root {
    --rxlib-principal: #FDA227;
    --rxlib-principal-01: #FDAB4C;
    --rxlib-principal-02: #FDA840;
    --rxlib-principal-03: #FDAE59;
    --rxlib-principal-rgb-50: rgb(253, 162, 39, 0.5);
    --rxlib-principal-rgb-70: rgb(253, 162, 39, 0.7);
    --rxlib-principal-shadow: rgba(215, 133, 38, 0.5);

    --rxlib-fonte: #FFFFFF;
    --rxlib-fonte-botao: #000000;

    --rxlib-link: #6C757D;
    --rxlib-menu: #363636;
    --rxlib-telas: #FFFFFF;
    --rxlib-borda: #E0E0E0;
    --rxlib-sistema: #363636;

    --rxlib-navbar: #363636;
    --rxlib-navbar-fonte: #FFFFFF;
    --rxlib-navbar-active: #6D6D6D;
    --rxlib-navbar-gradient: #6D6D6D;

    --rxlib-tabela-listrado-rgb-25: rgba(0, 0, 0, 0.05);
}

:root {
    --rxlib-principal: #01B4B8 !important;
    --rxlib-principal-01: #01C6CB !important;
    --rxlib-principal-02: #01BCC0 !important;
    --rxlib-principal-03: #01CCD1 !important;
    --rxlib-principal-rgb-50: rgb(1, 180, 184, 0.5) !important;
    --rxlib-principal-rgb-70: rgb(1, 180, 184, 0.7) !important;
    --rxlib-principal-shadow: rgba(1, 171, 175, 0.5) !important;

    --rxlib-fonte-botao: #FFFFFF !important;
    --rxlib-fonte-grid: #212529 !important;
}

body {
    background-color: var(--rxlib-telas) !important;
}

.rxlib-logo {
    width: 200px !important;
    height: 200px !important;
}

.rxlib-logo-retangulo {
    width: 15rem !important;
    height: 6rem !important;
}

.rxlib-login {
    width: 400px !important;
    padding: 20px !important;
    border-radius: 10px !important;
    border: 1px solid var(--rxlib-borda) !important;
    background-color: var(--rxlib-telas) !important;
}

.rxlib-login-retangulo {
    width: 400px !important;
    padding: 30px !important;
    border-radius: 10px !important;
    border: 1px solid var(--rxlib-borda) !important;
    background-color: var(--rxlib-telas) !important;
}

.rxlib-nome {
    font-size: 2rem !important;
    font-weight: bold !important;
    color: var(--rxlib-sistema) !important;
}

.rxlib-conteiner-logo {
    vertical-align: bottom !important;
}

.rxlib-homologacao {
    color: #FF0000 !important;
}

.rxlib-form {
    height: 80vh !important;
    overflow: auto !important;
    padding-bottom: 50px !important;
}

.rxlib-table-overflow {
    overflow-x: auto !important;
    white-space: nowrap !important;
}

.rxlib-celula-botao {
    align-self: flex-end !important;
}

.rxlib-list-group-item {
    background-color: var(--rxlib-telas) !important;
}

/* rxlib-navbar */

.rxlib-navbar-top {
    background-color: var(--rxlib-navbar) !important;
    border-bottom: 1px solid var(--rxlib-borda) !important;
}

.rxlib-navbar-nav {
    width: 31rem !important;
}

.rxlib-navbar-collapse {
    width: 90% !important;
}

.rxlib-navbar-brand {
    padding-top: 0rem !important;
}

.rxlib-logo-navbar {
    width: 2rem !important;
}

.rxlib-nome-navbar {
    font-weight: bold !important;
    margin-left: 1rem !important;
    color: var(--rxlib-principal) !important;
}

.rxlib-navbar-brand span {
    vertical-align: middle !important;
}

.rxlib-navbar-toggler-icon {
    color: var(--rxlib-principal) !important;
}

.rxlib-navbar-footer {
    background-color: var(--rxlib-navbar) !important;
    border-top: 1px solid var(--rxlib-borda) !important;
}

.rxlib-developer-by {
    width: 100% !important;
    font-size: 0.9rem !important;
    color: var(--rxlib-navbar-fonte) !important;
}

/* rxlib-sidebar */

.rxlib-sidebar {
    width: 17rem !important;
    height: 100vh !important;
}

.rxlib-navbar-sidebar-top {
    left: inherit !important;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}

.rxlib-navbar-sidebar-top-open {
    left: inherit !important;
}

.rxlib-navbar-sidebar-footer {
    left: inherit !important;
}

.rxlib-sidebar-header {
    display: flex !important;
    padding-top: .5rem !important;
    align-items: center !important;
    padding-bottom: .5rem !important;
    background-color: var(--rxlib-navbar) !important;
}

.rxlib-sidebar-header span {
    color: #FFFFFF !important;
    font-size: 1.5rem !important;
    margin-right: 1rem !important;
    line-height: 2.3rem !important;
}

.rxlib-sidebar-body {
    background-color: var(--rxlib-navbar) !important;
}

.rxlib-sidebar-body-gradient {
    background-image: linear-gradient(0deg, var(--rxlib-navbar-gradient) 0%, var(--rxlib-navbar) 100%) !important;
}

.rxlib-sidebar-logo-navbar {
    width: 2.5rem !important;
    margin-right: 1rem !important;
}

.rxlib-sidebar-logo {
    width: 2.5rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

.rxlib-sidebar-accordion-button {
    color: #000000 !important;
    background-color: var(--rxlib-navbar-active) !important;
}

.rxlib-sidebar-accordion-button:focus {
    box-shadow: none !important;
    border-color: var(--rxlib-navbar-active) !important;
}

.rxlib-sidebar-accordion-button:not(.collapsed) {
    color: #000000 !important;
    background-color: var(--rxlib-navbar-active) !important;
}

.rxlib-sidebar-accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    transform: rotate(180deg) !important;
}

.rxlib-sidebar-accordion-body {
    padding: 0rem !important;
}

.rxlib-sidebar-accordion-body ul li {
    padding: 0rem !important;
    background-color: transparent !important;
}

.rxlib-sidebar-accordion-body ul li a {
    padding: 0.5rem 1rem !important;
}

/* rxlib-select-label-async */

.rxlib-select {
    padding: 0px !important;
}

.rxlib-spinner-select {
    width: 20px !important;
    height: 20px !important;
    margin-right: 8px !important;
    border-width: .15em !important;
}

.rxlib-select-label-async-input {
    width: 95% !important;
    height: 0px !important;
    margin-bottom: 0.1rem !important;
}

.rxlib-select-label-async-input input {
    height: 0px !important;
    border: none !important;
    padding: 0.5px !important;
    color: #FFFFFF !important;
    margin-left: 0.5rem !important;
    margin-top: -0.1rem !important;
}

.rxlib-select-label-async-input input:focus {
    border: none !important;
    box-shadow: none !important;
}

.rxlib-select-label-async {
    width: 50% !important;
}

.rxlib-select-label-async-coluna {
    width: 100% !important;
}

.rxlib-select-label-async-coluna .rxlib-select-label-async-input input {
    color: #FFFFFF !important;
}

.rxlib-select-label-async-group {
    width: 100% !important;
}

.rxlib-select__control {
    height: 2.25rem !important;
    border-style: none !important;
    min-height: 2.25rem !important;
}

.rxlib-select__control--is-focused {
    border-color: var(--rxlib-principal) !important;
    box-shadow: 0 0 0 1.5px var(--rxlib-principal) !important;
}

.rxlib-select__control:hover {
    border-color: var(--rxlib-principal) !important;
}

.rxlib-select__control--is-disabled {
    background-color: #E9ECEF !important;
}

.rxlib-select__single-value--is-disabled {
    color: #000000 !important;
}

/* rxlib-btn-popover */

.rxlib-btn-popover {
    width: 100% !important;
    color: var(--rxlib-fonte) !important;
    border-color: var(--rxlib-principal) !important;
    background-color: var(--rxlib-principal) !important;
}

.rxlib-btn-popover:hover {
    color: var(--rxlib-fonte) !important;
    border-color: var(--rxlib-principal) !important;
    background-color: var(--rxlib-principal) !important;
}

.rxlib-btn-popover:focus {
    color: var(--rxlib-fonte) !important;
    border-color: var(--rxlib-principal) !important;
    background-color: var(--rxlib-principal) !important;
}

.btn-check:active+.rxlib-btn-popover:focus, .btn-check:checked+.rxlib-btn-popover:focus, .rxlib-btn-popover.active:focus, .rxlib-btn-popover:active:focus, .show>.rxlib-btn-popover.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.05rem var(--rxlib-principal-rgb-50) !important;
}

/* rxlib-exclusao */

.rxlib-exclusao-button {
    width: 15% !important;
    margin-right: 1rem !important;
}

.modal-content .rxlib-exclusao-button {
    width: 30% !important;
    margin-right: 1rem !important;
}

.rxlib-exclusao-button-com-spinner {
    width: 33% !important;
    margin-right: 1rem !important;
}

.rxlib-exclusao {
    margin-left: auto !important;
}

.rxlib-exclusao-com-spinner {
    margin-left: auto !important;
    margin-right: auto !important;
}

.rxlib-cancelar-button {
    width: 15% !important;
}

.modal-content .rxlib-cancelar-button {
    width: 30% !important;
}

.rxlib-cancelar {
    margin-right: auto !important;
}

.rxlib-voltar {
    margin-right: auto !important;
    margin-left: auto !important;
}

/* rxlib-listagem-detalhe */

.rxlib-listagem-detalhe {
    width: 50% !important;
    margin-bottom: 0rem !important;
}

.rxlib-listagem-detalhe-coluna {
    width: 100% !important;
}

/* rxlib-modal-primary */

.rxlib-modal-primary-header {
    border-bottom: 0rem !important;
    padding: 0.3rem 0.8rem !important;
}

.rxlib-modal-primary-body {
    padding: 0.3rem 0.8rem 0.7rem !important;
}

.rxlib-modal-primary-alert {
    border: 0rem !important;
    font-weight: 400 !important;
    margin-bottom: 0rem !important;
}

.rxlib-modal-primary-header .btn-close {
    padding: 0.9rem 0.7rem !important;
}

.rxlib-modal-primary-alert .modal-content {
    color: #084298 !important;
    border-color: #b6d4fe !important;
    background-color: #cfe2ff !important;
}

/* rxlib-modal-warning */

.rxlib-modal-warning-header {
    border-bottom: 0rem !important;
    padding: 0.3rem 0.8rem !important;
}

.rxlib-modal-warning-body {
    padding: 0.3rem 0.8rem 0.7rem !important;
}

.rxlib-modal-warning-alert {
    border: 0rem !important;
    font-weight: 400 !important;
    margin-bottom: 0rem !important;
}

.rxlib-modal-warning-header .btn-close {
    padding: 0.9rem 0.7rem !important;
}

.rxlib-modal-warning-alert .modal-content {
    color: #664d03 !important;
    border-color: #ffecb5 !important;
    background-color: #fff3cd !important;
}

/* rxlib-select-label */

.rxlib-select-label {
    width: 50% !important;
}

.rxlib-select-label-coluna {
    width: 100% !important;
}

/* rxlib-table */

.rxlib-coluna-acoes {
    width: 8rem !important;
}

.rxlib-coluna-acoes-detalhe {
    width: 20% !important;
}

.rxlib-coluna-acoes-personalizadas-1 {
    width: 10rem !important;
}

.rxlib-coluna-acoes-personalizadas-2 {
    width: 12rem !important;
}

.rxlib-coluna-acoes-personalizadas-3 {
    width: 14rem !important;
}

.rxlib-coluna-acoes-personalizadas-detalhe-1 {
    width: 25% !important;
}

.rxlib-coluna-acoes-personalizadas-detalhe-2 {
    width: 26% !important;
}

.rxlib-coluna-acoes-personalizadas-detalhe-3 {
    width: 27% !important;
}

.rxlib-btn-link-table {
    border: none !important;
    margin: 0rem !important;
    padding: 0rem !important;
    vertical-align: bottom !important;
    color: var(--rxlib-link) !important;
}

.rxlib-btn-link-table:hover {
    color: var(--rxlib-link) !important;
}

.rxlib-btn-link-table:focus {
    box-shadow: none !important;
    color: var(--rxlib-link) !important;
}

.rxlib-btn-csv-table {
    width: 10rem !important;
    max-width: 10rem !important;
    margin-left: -0.7rem !important;
}

/* rxlib-tree-permissoes */

.rxlib-checkbox-permissao {
    opacity: unset !important;
    will-change: transform, height !important;
}

/* rxlib-btn-order-table */

.rxlib-btn-order-table {
    border: none !important;
    margin: 0rem !important;
    padding: 0rem !important;
    margin-left: 0.3rem !important;
    color: var(--rxlib-link) !important;
}

.rxlib-btn-order-table:hover {
    box-shadow: none !important;
    color: var(--rxlib-link) !important;
}

.rxlib-btn-order-table:focus {
    box-shadow: none !important;
    color: var(--rxlib-link) !important;
}

.rxlib-btn-order-table .active {
    color: #FF0000 !important;
}

/* rxlib-card */

.rxlib-card {
    width: 25rem !important;
    height: 10rem !important;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2) !important;
}

.rxlib-card-title {
    font-size: 1.5rem !important;
}

.rxlib-card-text {
    display: flex !important;
}

.rxlib-card-text-muted {
    width: 24rem !important;
    font-weight: 500 !important;
    vertical-align: super !important;
}

.rxlib-card-icon {
    font-size: 5rem !important;
    margin-left: 1rem !important;
    color: var(--rxlib-principal) !important;
}

.rxlib-card-icon-disabled {
    color: #CCCCCC !important;
}

/* form-select */

.form-select:active {
    outline: 0 !important;
    border-color: var(--rxlib-principal-rgb-70) !important;
    box-shadow: 0 0 0 0.05rem var(--rxlib-principal-rgb-70) !important;
}

.form-select:hover {
    outline: 0 !important;
    border-color: var(--rxlib-principal-rgb-70) !important;
    box-shadow: 0 0 0 0.05rem var(--rxlib-principal-rgb-70) !important;
}

.form-select:focus {
    outline: 0 !important;
    border-color: var(--rxlib-principal-rgb-70) !important;
    box-shadow: 0 0 0 0.05rem var(--rxlib-principal-rgb-70) !important;
}

/* popover */

.popover-header {
    color: var(--rxlib-fonte) !important;
    background-color: var(--rxlib-principal) !important;
}

.bs-popover-auto[x-placement^=bottom]>.arrow, .bs-popover-bottom>.arrow {
    top: calc(-.5rem - 1px) !important;
}

.popover .arrow {
    width: 1rem !important;
    height: .5rem !important;
    margin: 0 .3rem !important;
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .bs-popover-bottom .popover-header::before {
    display: none !important;
}

/* list-group-item */

.list-group-item {
    border: none !important;
}

/* btn-close */

.btn-close:focus {
    box-shadow: none !important;
}

/* btn-check */

.btn-check:focus+.btn, .btn:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

/* treeview */

.treeview>.toggle {
    cursor: pointer !important;
    pointer-events: all !important;
    fill: var(--rxlib-principal) !important;
    color: var(--rxlib-principal) !important;
}

.treeview>.toggle:hover {
    fill: var(--rxlib-principal) !important;
    color: var(--rxlib-principal) !important;
}

/* dropdown */

.dropdown-menu {
    background-color: var(--rxlib-menu) !important;
}

.dropdown-item {
    color: var(--rxlib-navbar-fonte) !important;
}

.dropdown-item.active, .dropdown-item:active {
    text-decoration: none !important;
    color: var(--rxlib-fonte) !important;
    background-color: var(--rxlib-principal) !important;
}

.dropdown-item.focus, .dropdown-item:hover {
    text-decoration: none !important;
    color: var(--rxlib-fonte) !important;
    background-color: var(--rxlib-principal) !important;
}

/* navbar */

.navbar-dark .navbar-nav .nav-link {
    color: var(--rxlib-navbar-fonte) !important;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: var(--rxlib-navbar-fonte) !important;
}

.navbar-dark .navbar-toggler {
    border: none !important;
}

.navbar-light .navbar-nav .nav-link {
    color: var(--rxlib-navbar-fonte) !important;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: var(--rxlib-navbar-fonte) !important;
}

.navbar-light .navbar-toggler {
    border: none !important;
}

.navbar-toggler:focus {
    outline: 0 !important;
    box-shadow: none !important;
    text-decoration: none !important;
}

/* page-link */

.page-link {
    color: var(--rxlib-link) !important;
}

.page-link:hover {
    color: var(--rxlib-link) !important;
}

.page-link:focus {
    box-shadow: none !important;
}

.page-item.active .page-link {
    border-color: var(--rxlib-principal) !important;
    background-color: var(--rxlib-principal) !important;
}

/* table-striped */

.table-striped>tbody>tr:nth-of-type(odd) {
    --bs-table-accent-bg: var(--rxlib-tabela-listrado-rgb-25) !important;
}

/* react-tabs */

.react-tabs__tab--selected {
    background-color: var(--rxlib-telas) !important;
}

/* modal */

.modal-content {
    background-color: var(--rxlib-telas) !important;
}

/* vertical-timeline */

.vertical-timeline:before {
    background: #DDDDDD !important;
}

.vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px #DDDDDD, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%) !important;
}

.vertical-timeline-element-title i {
    color: var(--rxlib-principal) !important;
    margin-left: 1rem !important;
}

.vertical-timeline-modal-informacoes {
    width: 50rem !important;
    max-width: none !important;
}

.vertical-timeline-modal-informacoes .modal-content {
    height: 22.3rem !important;
}

.vertical-timeline-modal-informacoes-body {
    padding: 1rem 0rem 1rem 0rem !important;
}

.vertical-timeline-modal-informacoes-btn-close-cell {
    text-align: right !important;
    align-self: flex-end !important;
}

.vertical-timeline-button-informacoes {
    border: 0rem !important;
    padding: 0rem !important;
    font-size: 1.5rem !important;
    line-height: 0rem !important;
}

/* button-link */

.btn-link-select {
    width: 2.8rem !important;
    height: 2.4rem !important;
    padding-top: 0.35rem !important;
    padding-left: 0.60rem !important;
}

.col-btn-link {
    display: flex !important;
    align-items: flex-end !important;
}

.col-btn-link a {
    margin-left: 0.5rem !important;
}

/* rxlib-select-perfil */

.rxlib-select-perfil {
    width: 50% !important;
}

/* rxlib-modal-exclusao */

.rxlib-modal-exclusao {
    max-width: max-content !important;
}

.rxlib-modal-exclusao-body {
    padding: 1rem 0rem 1rem 0rem !important;
}

/* rxlib-breadcrumb */

.rxlib-breadcrumb {
    margin-top: 4rem !important;
}

/* rxlib-btn */

.rxlib-btn {
    width: 100% !important;
}

.btn-rxlib {
    color: var(--rxlib-fonte-botao) !important;
    border-color: var(--rxlib-principal) !important;
    background-color: var(--rxlib-principal) !important;
}

.btn-rxlib:hover {
    color: var(--rxlib-fonte-botao) !important;
    border-color: var(--rxlib-principal-02) !important;
    background-color: var(--rxlib-principal-01) !important;
}

.btn-check:focus+.btn-rxlib, .btn-rxlib:focus {
    color: var(--rxlib-fonte-botao) !important;
    border-color: var(--rxlib-principal-02) !important;
    background-color: var(--rxlib-principal-01);
    box-shadow: 0 0 0 0.05rem var(--rxlib-principal-shadow) !important;
}

.btn-check:checked+.btn-rxlib, .btn-check:active+.btn-rxlib, .btn-rxlib:active, .btn-rxlib.active, .show>.btn-rxlib.dropdown-toggle {
    color: var(--rxlib-fonte-botao) !important;
    border-color: var(--rxlib-principal-02) !important;
    background-color: var(--rxlib-principal-03) !important;
}

.btn-check:checked+.btn-rxlib:focus, .btn-check:active+.btn-rxlib:focus, .btn-rxlib:active:focus, .btn-rxlib.active:focus, .show>.btn-rxlib.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.05rem var(--rxlib-principal-shadow) !important;
}

.btn-rxlib:disabled, .btn-rxlib.disabled {
    color: var(--rxlib-fonte-botao) !important;
    border-color: var(--rxlib-principal) !important;
    background-color: var(--rxlib-principal) !important;
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
    box-shadow: 0 0 0 0.05rem rgba(49, 132, 253, 0.5) !important;
}

.btn-check:checked+.btn-primary:focus, .btn-check:active+.btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.05rem rgba(49, 132, 253, 0.5) !important;
}

.btn-check:focus+.btn-secondary, .btn-secondary:focus {
    box-shadow: 0 0 0 0.05rem rgba(130, 138, 145, 0.5) !important;
}

.btn-check:checked+.btn-secondary:focus, .btn-check:active+.btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.05rem rgba(130, 138, 145, 0.5) !important;
}

.btn-check:focus+.btn-success, .btn-success:focus {
    box-shadow: 0 0 0 0.05rem rgba(60, 153, 110, 0.5) !important;
}

.btn-check:checked+.btn-success:focus, .btn-check:active+.btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.05rem rgba(60, 153, 110, 0.5) !important;
}

.btn-check:focus+.btn-info, .btn-info:focus {
    box-shadow: 0 0 0 0.05rem rgba(11, 172, 204, 0.5) !important;
}

.btn-check:checked+.btn-info:focus, .btn-check:active+.btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.05rem rgba(11, 172, 204, 0.5) !important;
}

.btn-check:focus+.btn-warning, .btn-warning:focus {
    box-shadow: 0 0 0 0.05rem rgba(217, 164, 6, 0.5) !important;
}

.btn-check:checked+.btn-warning:focus, .btn-check:active+.btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.05rem rgba(217, 164, 6, 0.5) !important;
}

.btn-check:focus+.btn-danger, .btn-danger:focus {
    box-shadow: 0 0 0 0.05rem rgba(225, 83, 97, 0.5) !important;
}

.btn-check:checked+.btn-danger:focus, .btn-check:active+.btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.05rem rgba(225, 83, 97, 0.5) !important;
}

.btn-check:focus+.btn-light, .btn-light:focus {
    box-shadow: 0 0 0 0.05rem rgba(211, 212, 213, 0.5) !important;
}

.btn-check:checked+.btn-light:focus, .btn-check:active+.btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.05rem rgba(211, 212, 213, 0.5) !important;
}

.btn-check:focus+.btn-dark, .btn-dark:focus {
    box-shadow: 0 0 0 0.05rem rgba(66, 70, 73, 0.5) !important;
}

.btn-check:checked+.btn-dark:focus, .btn-check:active+.btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.05rem rgba(66, 70, 73, 0.5) !important;
}

/* rxlib-spinner */

.rxlib-spinner {
    --bs-text-opacity: 1 !important;
    color: var(--rxlib-principal) !important;
}

/* rxlib-button-crud */

.rxlib-button-crud {
    display: flex !important;
}

.rxlib-button-crud .button-salvar {
    width: 10rem !important;
    margin-right: 1rem !important;
}

.rxlib-button-crud .button-cancelar-voltar {
    width: 10rem !important;
}

.rxlib-button-crud .spinner-carregando {
    min-width: 20rem !important;
    margin-right: 2rem !important;
}

@media (min-width: 320px) and (max-width: 413px) {

    .rxlib-button-crud {
        display: grid !important;
    }

    .rxlib-button-crud .button-salvar {
        width: 100% !important;
    }

    .rxlib-button-crud .button-cancelar-voltar {
        width: 100% !important;
        margin-top: 0.5rem !important;
    }

    .rxlib-button-crud .spinner-carregando {
        min-width: 1rem !important;
        margin-right: 0rem !important;
    }
}

@media (min-width: 414px) and (max-width: 835px) {

    .rxlib-button-crud {
        display: grid !important;
    }

    .rxlib-button-crud .button-salvar {
        width: 100% !important;
    }

    .rxlib-button-crud .button-cancelar-voltar {
        width: 100% !important;
        margin-top: 0.5rem !important;
    }

    .rxlib-button-crud .spinner-carregando {
        min-width: 1rem !important;
        margin-right: 0rem !important;
    }
}

/* rxlib-checkbox */

.form-control:focus {
    border-color: var(--rxlib-principal-02) !important;
    box-shadow: 0 0 0 0.05rem var(--rxlib-principal-shadow) !important;
}

.form-check-input:focus {
    border-color: var(--rxlib-principal-02) !important;
    box-shadow: 0 0 0 0.05rem var(--rxlib-principal-shadow) !important;
}

.form-check-input:checked {
    border-color: var(--rxlib-principal) !important;
    background-color: var(--rxlib-principal) !important;
}

/* rxlib-link */

a {
    text-decoration: none !important;
    color: var(--rxlib-link) !important;
}

a:hover {
    text-decoration: none !important;
    color: var(--rxlib-link) !important;
}

/* rxlib-textarea-label */

.rxlib-textarea-label-linha {
    width: 50%;
}

.rxlib-textarea-label-coluna {
    width: 100%;
}

@media (min-width: 320px) and (max-width: 413px) {

    .rxlib-textarea-label-linha {
        width: 100%;
    }
}

@media (min-width: 414px) and (max-width: 835px) {

    .rxlib-textarea-label-linha {
        width: 100%;
    }
}